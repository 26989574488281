.scrollable-list {
  max-height: 420px; /* Set the desired height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional border for separation */
  padding: 10px;
  margin: 20px; /* Separate it from the main content */
}
/* Optional styling for the ordered list */
.scrollable-list ol {
  padding-left: 20px;
  margin: 0;
}
