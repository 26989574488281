.draggable-box-webcamera {
  position: fixed;
  width: 150px;
  height: 150px;
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  z-index: 1000;
  cursor: move;
}

.draggable-box-screen {
  position: fixed;
  width: 150px;
  height: 150px;
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  z-index: 1000;
  cursor: move;
}

video {
  width: 100%;
  height: 100%;
}

.editor {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 300px;
  width: 100%;
  outline: none;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
  cursor: move;
}
