.sign-up {
  background: #f4e2d8;
  animation: gradient-move 15s linear infinite;
}
.react-datepicker__input-container {
  width: inherit;
}

.account-sign-in {
  color: #0b5ed7;
  font-weight: 500;
}
.account-sign-in:hover {
  opacity: 0.6;
}
@keyframes gradient-move {
  10% {
    background-color: #ff5f6d;
  }
  20% {
    background-color: rgb(255, 199, 121);
  }
  40% {
    background-color: #ff9068;
  }
  60% {
    background-color: #cb3066;
  }
  80% {
    background-color: #16bffd;
  }
  90% {
    background-color: #eecda3;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d77272;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 4px;
  /* bottom: 4px; */
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(42px);
  -ms-transform: translateX(42px);
  transform: translateX(42px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
