.login__submit {
  background: #fff;
  font-size: 14px;
  /* margin-top: 30px; */
  padding: 5px 20px;
  border-radius: 18px;
  border: 1px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #4c489d;
  box-shadow: 0px 2px 2px #5c5696;
  cursor: pointer;
  transition: 0.2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #6a679e;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875b5;
}

.login__submit:hover {
  opacity: 0.9;
  color: #7875b5;
}
