/* src/components/LandingPage.css */
.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 700px !important; */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
  aspect-ratio: 1;
}
